.service_spot {

	overflow: hidden;
	padding: 2em 1em;

	&:not(:last-child) { border-bottom: 1px solid #ccc; }

	> *:last-child { margin-bottom: 0; }

	h2 {
		margin: 0 0 0.5em;
		font-weight: 600;
	}

	img {
		max-width: 360px;
		margin: 0 0 1em;
		padding: 0.25em;
		border: 1px solid #ccc;
		background-color: $white;
	}

	p { font-size: 1.125rem; line-height: 1.7em }

	ul > li:not(:last-child) { margin-bottom: 0.75em }

	@include media-breakpoint-up(sm) {
		img {
			float: right;
			width: 45%;
			margin: 0 0 1em 1em;
			border-radius: 50%;
		}
	}

	@include media-breakpoint-up(md) {
		img {
			margin: 0 0 1.5em 1.5em;
		}
	}

	@include media-breakpoint-up(lg) {
		img { margin-top: -3em; }
	}

	@include media-breakpoint-up(xl) {
		padding: 2em 3em;
		img { margin-left: 2.5em; }
	}

}


.junk-removal-listings {

	margin-top: 15px;
	border-top: 1px solid #cccccc;

	p {
		margin: 0;
		padding: 30px 15px;
		strong { display: block; }
		em { font-style: normal; }
		&:nth-child(even) {
			background-color: rgba(white, 0.8);
		}
	}

	@include media-breakpoint-up(md) {
		p { position: relative; }
		p strong { display: inline-block; }
		em {
			display: inline-block;
			float: right;
		} 
	}

}