$menu_link_bg:				#ccc;
$menu_link_color:			$color-text;
$menu_hover_bg:				#999;
$menu_hover_color:			$color-text;
$menu_active_bg:			$white;
$menu_active_color:			$color-text;

$dropdown_padding: 			10px;
$dropdown_link_bg: 			$menu_hover_bg;
$dropdown_link_color: 		$color-text;
$dropdown_hover_bg: 		rgba(255,255,255,0.3);
$dropdown_hover_color: 		$color-text;
$dropdown_divider_dark: 	1px solid rgba(0,0,0,0.3);
$dropdown_divider_lite:		1px solid rgba(255,255,255,0.7);

$nav_separator_left: 1px solid rgba(0, 0, 0, 1);
$nav_separator_right: 1px solid rgba(255, 255, 255, 0.4);

$nav_separator_top: 1px solid rgba(255, 255, 255, 0.4);
$nav_separator_bottom: 1px solid rgba(0, 0, 0, 0.45);

.nav_menu {
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

	.menu_item {
		box-sizing: border-box;
		list-style-type: none;

		&.active .menu_link {
			cursor: default;
			&:hover { cursor: default; }
		}

		&.open {
			> .menu_link {
				background-color: $menu_hover_bg;
				color: $menu_hover_color;
			}
			.dropdown_menu {
				display: block;
				height: auto;
				background-color: $menu_hover_bg;
			}
			.caret {
				@include transform(rotate(180deg));
			}
		}

	}

		.menu_link {
			@include transition(all ease-in-out 200ms);
			display: block;
			padding: 1.5em 0.5em;
			line-height: 1em; 
			text-decoration: none;
			background-color: $menu_link_bg;
			color: $menu_link_color;			
			&:hover,
			&:focus {
				@include transition(all ease-in-out 200ms);
				background-color: $menu_hover_bg;
				color: $menu_hover_color;
				outline: none;
			}

			.nav_primary & { background-color: $color-primary; color: $white; &:hover, &:focus { background-color: lighten($color-primary, 15%) } }
			.nav_secondary & { background-color: $color-secondary; color: $white; &:hover, &:focus { background-color: lighten($color-secondary, 15%) } }
			.nav_highlight & { background-color: $color-highlight; color: $white; &:hover, &:focus { background-color: lighten($color-highlight, 15%) } }
			.nav_accent & { background-color: $color-accent; color: $white; &:hover, &:focus { background-color: lighten($color-accent, 15%) } }
			.nav_success & { background-color: $color-success; color: $white; &:hover, &:focus { background-color: lighten($color-success, 15%) } }
			.nav_danger & { background-color: $color-danger; color: $white; &:hover, &:focus { background-color: lighten($color-danger, 15%) } }
			.nav_warning & { background-color: $color-warning; color: $white; &:hover, &:focus { background-color: lighten($color-warning, 15%) } }
			.nav_info & { background-color: $color-info; color: $white; &:hover, &:focus { background-color: lighten($color-info, 15%) } }

		}

			.caret {
				display: inline-block;
				position: relative;
				vertical-align: middle;
				width: 0.85em;
				height: 0.85em;
				background-image: url("/_/images/icons/dropdown.svg");
				background-repeat: no-repeat;
				background-size: 0.85em;
				background-position: center center;
			}

		.dropdown_menu {

			@include transition(all ease-in-out 300ms);
			display: none;
			margin: 0;
			padding: $dropdown_padding;
			min-width: 280px;

			.menu_item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				text-align: left;
				&:not(:last-child) { border-bottom: $dropdown_divider_dark; }
				&:not(:first-child) { border-top: $dropdown_divider_lite; }
			}

				.menu_link {
					padding-left: 10px;
					background-color: $dropdown_link_bg;
					color: $dropdown_link_color;
					&:hover, &:focus {
						outline: none;					
						background-color: $dropdown_hover_bg;
						color: $dropdown_hover_color;
					}				
				}

		}
