.page-title {
	position: relative;
	z-index: 70000;
	padding: 2em 0;
	color: $white;
	border-bottom: 5px solid #ccc;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);
	@extend .gdfancybg--primary;

	h1 { margin: 0; line-height: 1em; }

	@include media-breakpoint-up(sm) { padding: 4em 0 1em; }
	@include media-breakpoint-up(md) { padding-top: 5em; }
	@include media-breakpoint-up(lg) { padding-top: 7em; }
	@include media-breakpoint-up(xl) { padding-top: 9em; }


	.post & {
		h1 { max-width: 730px; }
	}

}