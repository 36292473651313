$jumbo-bg: 			$color-primary;
$jumbo-color: 		$white;

.jumbo {

	position: relative;
	background-color: $jumbo-bg;
	color: $jumbo-color;

	.carousel { display: none; }

	@include media-breakpoint-only(md) {
		.carousel { border-bottom: 5px solid $white }
	}

	@include media-breakpoint-up(md) {
		padding: 0 0 2em;
		.carousel { display: block; }
	}

	@include media-breakpoint-up(lg) {
		padding: 2em 0;
		background-color: $white;
		.carousel {
			position: absolute;
			top: 0; bottom: 0;
			left: 0; right: 0;
			width: 100%;
			.carousel-inner,
			.item,
			.item img { height: 100%; }
		}
	}

}


	.jumbo_marketing {

		.marketing_title {
			margin-bottom: 0.5em;
			font-size: 1.2rem;
			font-weight: 600;
			line-height: 1.25em;
		}

			.marketing_list > li {
				padding-left: 1.9rem;
				font-size: 1.1rem;
				font-weight: 600;
				line-height: 1.25em;
			}

		@include media-breakpoint-only(md) {
			padding-top: 1.5em;
		}

		@include media-breakpoint-up(lg) {

			.marketing_title {
				display: inline;
				padding: 0.05em 0;
				font-size: 1.7rem;
				line-height: 1.3em;
				text-shadow: 0 1px 1px #000;
				background-color: rgba(0, 0, 0, 0.7);
				box-shadow:		-0.4em 0 0 0 rgba(0, 0, 0, 0.7),
								0.4em 0 0 0 rgba(0, 0, 0, 0.7);
				&:after {
					display: block;
					height: 0.5em;
					content: "";
				}
			}

			.marketing_list {
				display: block;
				width: 100%;
				clear: both;
				margin: 0.5em 0;

				li {
					display: inline;
					padding: 0.2em 0 0.2em 1.3em;
					font-size: 1.5rem;
					text-shadow: 0 1px 1px #333;
					background-color: rgba(#8F7424, 0.8);
					box-shadow:		-0.6em 0 0 0 rgba(#8F7424, 0.8),
									0.6em 0 0 0 rgba(#8F7424, 0.8);
					&:not(:last-child):after {
						display: block;
						height: 0.75em;
						content: "";
					}
				}

			}

		}

	}