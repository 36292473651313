.nav_list {

	.menu_item:not(:last-child) { margin-bottom: 0.25em; }

}



	.menu_coverage .nav_menu {

		.menu_item > .menu_link {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			background-color: transparent;
			border-color: transparent;
			color: $color-primary;
			&:hover {
				background-color: $color-primary;
				color: $white;
			}
		}

		.menu_item.active > .menu_link {
			font-weight: 600;
			color: $color-secondary;
			&:hover {
				background-color: transparent;
				color: $color-secondary;
			}
		}

	}