
$logo_width-max: 		200px;
$logo_path: 			url("/_/images/layout/tv-plumbing.png");
$logo_hover:			lighten(#8F7424, 25%);

.site_branding {
	padding: 0;
}

	.branding_logo {

		display: block;
		box-sizing: border-box;
		max-width: $logo_width-max;
		margin: 0 auto;
		@include transition(all ease-in-out 200ms);

		#logo {
			* { @include transition(all ease-in-out 200ms); }
			.roof { fill:#333 }
			//.window { fill: #D9C383 }
			.window { fill: #999 }
			.shadow { fill: #999 }
			#text_e { fill: #8F7424 }
			#text_c { fill: #BF9B30 }
			#text_s { fill: #333 }
		}

		&:hover {
			#logo {
				* { @include transition(all ease-in-out 200ms); }
				.window { fill: $logo_hover }
				//#text_e { fill: $logo_hover }
				//#text_c { fill: $logo_hover }
			}
		}

		@include media-breakpoint-up(lg) {
			margin-left: 0;
			margin-right: 0;
		}


		.page_front & {
			max-width: 360px;
			@include media-breakpoint-up(lg) {
				margin-left: auto;
				margin-right: auto;
			}
		}

	}

	.branding_slogan {
		margin: 1em 0 0;
		font-size: 1.4rem;
		font-family: $font-family-serif;
		font-style: italic;
		line-height: 1em;
		text-align: center;
		color: #444;

		@include media-breakpoint-up(md) {
			margin-bottom: 0.5em;
		}

	}

