$banner-bg: 							$white !default;
$banner-color: 							$color-text !default;

.banner {

	padding: 0.75em 0;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.7);
	background-color: $banner-bg;
	color: $banner-color;
	border-bottom: 	6px solid #ccc;

	.page_front & {
		padding: 2em 0;
		border-bottom: none;
		box-shadow: none;
	}

}